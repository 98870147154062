import axios from 'axios';

const API_URL = process.env.REACT_APP_VEXPO_API_URL;

const createWebinar = (
  eventDomain: string,
  webinarName: string,
  webinarDescription: string,
  timeZone: string,
  startTime: string,
  endTime: string,
  maxAudiences: string,
  recordingEnabled: boolean,
  createWebinarPassword: string,
) => (
  axios.post(`${API_URL}/webinarAgora/${eventDomain}/webinars`, {
    name: webinarName,
    description: webinarDescription,
    timezone: timeZone,
    startTime: new Date(startTime)?.toISOString(),
    endTime: new Date(endTime)?.toISOString(),
    maxAudiences: parseInt(maxAudiences, 10),
    recordingEnabled,
    createWebinarPassword,
  })
    .then((result) => (result))
    .catch(({ response }) => (response))
);

export default createWebinar;
