import React, {
  FC,
  useState,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
} from '@material-ui/core';

const StyledModal = styled(Modal)`
  display: flex,;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #efefef;
  display: flex;
  flex-direction: column;
`;

const SpeakerNameTitleContainer = styled.div`
  flex: 1;
`;

interface SpeakerNameModalProps {
  updateSpeakerName: (speakerName: string) => void
}

const SpeakerNameModal: FC<SpeakerNameModalProps> = ({ updateSpeakerName }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [speakerName, setSpeakerName] = useState('');
  const handleSubmit = useCallback(() => {
    if (speakerName.trim() !== '') {
      updateSpeakerName(speakerName);
      setIsOpen(false);
    }
  }, [speakerName, updateSpeakerName]);

  const handleSpeakerNameChange = useCallback((e) => {
    setSpeakerName(e.target.value);
  }, [setSpeakerName]);

  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <ModalContent>
          <SpeakerNameTitleContainer>
            <h2 id="transition-modal-title">Please Enter Your Name</h2>
          </SpeakerNameTitleContainer>
          <TextField label="Speaker Name" onChange={handleSpeakerNameChange} />
          <Button onClick={handleSubmit}>Submit</Button>
        </ModalContent>
      </Fade>
    </StyledModal>
  );
};

export default SpeakerNameModal;
