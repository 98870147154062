import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
} from '@material-ui/core';

const StyledModal = styled(Modal)`
  display: flex,;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #efefef;
  display: flex;
  flex-direction: column;
`;

const EndWebinarTextContainer = styled.div`
  flex: 1;
`;

const EndWebinarButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledButton = styled(Button)``;

interface WebinarEndModelProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setIsStreaming: (isStreaming: boolean) => void
  handleRecordingEnd: () => void
}

const WebinarEndModal: FC<WebinarEndModelProps> = ({
  isOpen,
  setIsOpen,
  setIsStreaming,
  handleRecordingEnd,
}) => {
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const EndWebinar = useCallback(() => {
    setIsStreaming(false);
    handleRecordingEnd();
    handleClose();
  }, [setIsStreaming, handleClose, handleRecordingEnd]);

  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <ModalContent>
          <EndWebinarTextContainer>
            <h2 id="transition-modal-title">Confirm To End Webinar?</h2>
          </EndWebinarTextContainer>
          <EndWebinarButtonContainer>
            <StyledButton onClick={EndWebinar}>OK</StyledButton>
            <StyledButton onClick={handleClose}>Cancel</StyledButton>
          </EndWebinarButtonContainer>
        </ModalContent>
      </Fade>
    </StyledModal>
  );
};

export default WebinarEndModal;
