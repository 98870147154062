import React, {
  FC,
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import styled from 'styled-components';
import { useObservable } from 'react-use';
import {
  Button, FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import {
  Mic,
  MicOff,
  Videocam,
  VideocamOff,
  ScreenShare,
  CancelPresentation,
} from '@material-ui/icons';
import {
  defer,
} from 'rxjs';
import AgoraRTC from 'agora-rtc-sdk-ng';
import WebinarEndModal from './WebinarEndModal';

interface WebinarSettingsProps {
  selectedCameraId?: string
  setSelectedCameraId: (id: string) => void;
  selectedMicrophoneId?: string
  setSelectedMicrophoneId: (id: string) => void
  setIsCameraOn: (isCameraOn: boolean) => void
  isCameraOn: boolean
  setIsMicrophoneOn: (isMicrophoneOn: boolean) => void
  isMicrophoneOn: boolean
  isStreaming: boolean
  setIsStreaming: (isStreaming: boolean) => void
  isScreenShareEnabled: boolean
  setIsScreenShareEnabled: (isScreenShareEnabled: boolean) => void
  handleRecordingStart: () => void
  handleRecordingEnd: () => void
  connectionState: number
}

const EMPTY_ARRAY = [] as any[];

const WebinarSettingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const WebinarSettingsRowContainer = styled.div`

`;

const StyledFormControl = styled(FormControl)`
  width: 300px;
`;

const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    border: 1px solid #121f42;
    margin: 4px;
  }
`;

const WebinarSettings: FC<WebinarSettingsProps> = ({
  selectedCameraId,
  setSelectedCameraId,
  selectedMicrophoneId,
  setSelectedMicrophoneId,
  setIsCameraOn,
  isCameraOn,
  setIsMicrophoneOn,
  isMicrophoneOn,
  isStreaming,
  setIsStreaming,
  isScreenShareEnabled,
  setIsScreenShareEnabled,
  handleRecordingStart,
  handleRecordingEnd,
  connectionState,
}) => {
  const [isEndWebinarModelOpen, setIsEndWebinarModelOpen] = useState(false);
  const cameras$ = useMemo(() => defer(() => AgoraRTC.getCameras()), []);
  const cameras = useObservable(cameras$) ?? EMPTY_ARRAY;
  useEffect(() => {
    if ((selectedCameraId == null || cameras?.find((it) => it.deviceId === selectedCameraId) == null) && cameras?.length > 0) {
      setSelectedCameraId(cameras?.[0]?.deviceId as string);
    }
  }, [cameras, selectedCameraId, setSelectedCameraId]);

  const selecteCamera = useCallback((e) => {
    setSelectedCameraId(e.target.value as string);
  }, [setSelectedCameraId]);

  const microphones$ = useMemo(() => defer(() => AgoraRTC.getMicrophones()), []);
  const microphones = useObservable(microphones$) ?? EMPTY_ARRAY;

  useEffect(() => {
    if ((selectedMicrophoneId == null || microphones?.find((it) => it.deviceId === selectedMicrophoneId) == null) && microphones?.length > 0) {
      setSelectedMicrophoneId(microphones?.[0]?.deviceId as string);
    }
  }, [microphones, selectedMicrophoneId, setSelectedMicrophoneId]);

  const selecteMicrophone = useCallback((e) => {
    setSelectedMicrophoneId(e.target.value as string);
  }, [setSelectedMicrophoneId]);

  const toggleCamera = useCallback(() => setIsCameraOn(!isCameraOn), [isCameraOn, setIsCameraOn]);
  const toggleMicrophone = useCallback(() => setIsMicrophoneOn(!isMicrophoneOn), [isMicrophoneOn, setIsMicrophoneOn]);

  const renderLiveStreamButtonText = useMemo(() => {
    if (connectionState < 1) {
      return 'Connecting';
    }
    if (isStreaming) {
      return 'Stop LiveStreaming';
    }
    return 'Start LiveStream';
  }, [connectionState, isStreaming]);

  const toggleIsScreenShareEnabled = useCallback(() => {
    setIsScreenShareEnabled(!isScreenShareEnabled);
  }, [isScreenShareEnabled, setIsScreenShareEnabled]);

  const toggleLiveSteam = useCallback(() => {
    if (isStreaming) {
      setIsEndWebinarModelOpen(true);
    } else {
      setIsStreaming(true);
      handleRecordingStart();
    }
  }, [isStreaming, setIsStreaming, handleRecordingStart]);

  return (
    <WebinarSettingsContainer>
      <WebinarEndModal
        isOpen={isEndWebinarModelOpen}
        setIsOpen={setIsEndWebinarModelOpen}
        setIsStreaming={setIsStreaming}
        handleRecordingEnd={handleRecordingEnd}
      />
      <WebinarSettingsRowContainer>
        <StyledButton onClick={toggleMicrophone}>
          {isMicrophoneOn ? (
            <>
              <MicOff />
              Disabled Audio
            </>
          ) : (
            <>
              <Mic />
              Enable Audio
            </>
          )}
        </StyledButton>
        <StyledButton onClick={toggleCamera}>
          {isCameraOn ? (
            <>
              <Videocam />
              Disabled Camera
            </>
          ) : (
            <>
              <VideocamOff />
              Enable Camera
            </>
          )}
        </StyledButton>
        <StyledButton onClick={toggleIsScreenShareEnabled}>
          {isScreenShareEnabled ? (
            <>
              <CancelPresentation />
              Stop Sharing Screen
            </>
          ) : (
            <>
              <ScreenShare />
              Share Screen
            </>
          )}
        </StyledButton>
        <StyledButton onClick={toggleLiveSteam} disabled={connectionState < 1}>
          {renderLiveStreamButtonText}
        </StyledButton>
      </WebinarSettingsRowContainer>
      <WebinarSettingsRowContainer>
        <StyledFormControl>
          <InputLabel>Camera</InputLabel>
          <Select
            value={selectedCameraId}
            onChange={(e) => selecteCamera(e)}
          >
            {cameras?.map((camera) => (
              <MenuItem value={camera.deviceId} key={camera.deviceId}>{camera.label}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <StyledFormControl>
          <InputLabel>Microphone</InputLabel>
          <Select
            value={selectedMicrophoneId}
            onChange={(e) => selecteMicrophone(e)}
          >
            {microphones?.map((microphone) => (
              <MenuItem value={microphone.deviceId} key={microphone.deviceId}>{microphone.label}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </WebinarSettingsRowContainer>
    </WebinarSettingsContainer>
  );
};

export default WebinarSettings;
