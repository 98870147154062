import axios from 'axios';

const API_URL = process.env.REACT_APP_VEXPO_API_URL;

const endRecording = (eventDomain: string, webinarId: string, webinar: any) => {
  axios.post(`${API_URL}/webinarAgora/${eventDomain}/webinars/${webinarId}/recording/end`, {
    agoraWebinar: webinar,
  })
    .then(({ data }) => {
      if (data.success) {
        console.log('Recording Ended');
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export default endRecording;
