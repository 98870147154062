import axios from 'axios';

const API_URL = process.env.REACT_APP_VEXPO_API_URL;

const fetchSpeaker = async (
  eventDomain: string,
  webinarId: string,
  webinar: any,
  audienceJoinToken: string,
  vexpoAttendeeToken: string,
) => (
  axios.post(`${API_URL}/webinarAgora/${eventDomain}/webinars/${webinarId}/audiences`, {
    agoraWebinar: webinar,
    audienceJoinToken,
    vexpoAttendeeToken,
  })
    .then(({ data }) => {
      if (data.success) {
        return data.tokenInfo;
      }
      return null;
    })
    .catch((error) => {
      console.log(error);
      return null;
    })
);

export default fetchSpeaker;
