import axios from 'axios';

const API_URL = process.env.REACT_APP_VEXPO_API_URL;

const fetchWebinar = async (eventDomain: string, webinarId: string) => (
  axios.get(`${API_URL}/webinarAgora/${eventDomain}/webinars/${webinarId}`)
    .then(({ data }) => {
      if (data.success) {
        return data.webinar;
      }
      return null;
    })
    .catch((error) => {
      console.log(error);
      return null;
    })
);

export default fetchWebinar;
