import React, { FC } from 'react';
import styled from 'styled-components';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

const ControlBarContainer = styled.div`
  width: 100%;
  height: 160px;  
  position: absolute;
  bottom: -80px;
  left: 0px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: bottom 0.4s ease-out 2s;

  :hover {
    bottom: 0px;
    transition: bottom 0.4s;

  }
`;

const ControlBar = styled.div`
  width: 100%;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const FullscreenButton = styled.div`
  width: 150px;
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
`;

const StyledFullscreenIcon = styled(FullscreenIcon)`
  &.MuiSvgIcon-root {
    width: 40px;
    height: 40px;
    flex: 1;
  }
`;

const StyledVolumeUpIcon = styled(VolumeUpIcon)`
  &.MuiSvgIcon-root {
    width: 40px;
    height: 40px;
    flex: 1;
  }
`;

const StyledVolumeOffIcon = styled(VolumeOffIcon)`
  &.MuiSvgIcon-root {
    width: 40px;
    height: 40px;
    flex: 1;
  }
`;

const StyledFullscreenExitIcon = styled(FullscreenExitIcon)`
  &.MuiSvgIcon-root {
    width: 40px;
    height: 40px;
    flex: 1;
  }
`;

const LiveIndicator = styled.div`
  padding: 8px 16px;
  background: red;
`;

interface BottomControlBarProps {
  isRecordingMode: boolean;
  handleAudioMuteToggle: () => void;
  isAudioMuted: boolean;
  videoStreaming: boolean;
  audioStreaming: boolean;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
}

const BottomControlBar: FC<BottomControlBarProps> = ({
  isRecordingMode,
  handleAudioMuteToggle,
  isAudioMuted,
  videoStreaming,
  audioStreaming,
  isFullScreen,
  toggleFullScreen,
}) => {
  if (!isRecordingMode) {
    return (
      <ControlBarContainer>
        <ControlBar>
          <FullscreenButton onClick={handleAudioMuteToggle}>
            {isAudioMuted ? (
              <>
                <StyledVolumeUpIcon />
                Unmute
              </>
            ) : (
              <>
                <StyledVolumeOffIcon />
                Mute
              </>
            )}
          </FullscreenButton>
          <RightContainer>
            {(videoStreaming || audioStreaming) && (
              <LiveIndicator>
                LIVE
              </LiveIndicator>
            )}
            <FullscreenButton onClick={toggleFullScreen}>
              {isFullScreen ? (
                <>
                  <StyledFullscreenExitIcon />
                  Exit Full Screen
                </>
              ) : (
                <>
                  <StyledFullscreenIcon />
                  Full Screen
                </>
              )}
            </FullscreenButton>
          </RightContainer>
        </ControlBar>
      </ControlBarContainer>
    );
  }
  return null;
};

export default BottomControlBar;
