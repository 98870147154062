import React, { FC, useState, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
} from '@material-ui/core';
import createWebinar from '../network/createWebinar';

const CreateFormContainer = styled.div`
  width: 50%;
  height: 100%;
  padding: 24px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const CreateForm: FC = () => {
  const [eventDomain, setEventDomain] = useState<string>('');
  const [webinarDescription, setWebinarDescription] = useState<string>('');
  const [webinarName, setWebinarName] = useState<string>('');
  const [timeZone, setTimeZone] = useState<string>(moment.tz.guess());
  const [startTime, setStartTime] = useState<string>(moment().format('YYYY-MM-DDTHH:MM'));
  const [endTime, setEndTime] = useState<string>(moment().add(1, 'days').format('YYYY-MM-DDTHH:MM'));
  const [maxAudiences, setMaxAudiences] = useState<string>('1000');
  const [recordingEnabled, setRecordingEnabled] = useState<boolean>(true);
  const [createWebinarPassword, setCreateWebinarPassword] = useState('');
  const [webinar, setWebinar] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [creatingWebinar, setCreatingWebinar] = useState(false);

  const timeZoneList = moment.tz.names();
  const CreateWebinar = useCallback(async () => {
    setWebinar(null);
    setError(null);
    setCreatingWebinar(true);
    const result = await createWebinar(
      eventDomain,
      webinarName,
      webinarDescription,
      timeZone,
      startTime,
      endTime,
      maxAudiences,
      recordingEnabled,
      createWebinarPassword,
    );
    if (result?.data?.success) {
      setWebinar({ ...result.data.webinar, eventDomain });
    } else {
      setError(result?.data?.error);
    }
    setCreatingWebinar(false);
  }, [
    eventDomain,
    webinarDescription,
    webinarName,
    timeZone,
    startTime,
    endTime,
    maxAudiences,
    recordingEnabled,
    createWebinarPassword,
  ]);

  return (
    <CreateFormContainer>
      <h2>Create Webinar Form</h2>
      <TextField label="Event Domain" value={eventDomain} onChange={(e) => setEventDomain(e.target.value)} />
      <TextField label="Webinar Name" value={webinarName} onChange={(e) => setWebinarName(e.target.value)} />
      <TextField label="Webinar Description" value={webinarDescription} onChange={(e) => setWebinarDescription(e.target.value)} />
      <FormControl>
        <InputLabel id="Time-Zone">Time Zone</InputLabel>
        <Select
          labelId="Time-Zone"
          value={timeZone}
          onChange={(e) => setTimeZone(e.target.value as string)}
        >
          {timeZoneList.map((tz) => (
            <MenuItem value={tz} key={tz}>{tz}</MenuItem>
          ))}
        </Select>

      </FormControl>
      <TextField
        label="Start Time"
        type="datetime-local"
        value={startTime}
        onChange={((e) => setStartTime(e.target.value))}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label="End Time"
        type="datetime-local"
        value={endTime}
        onChange={((e) => setEndTime(e.target.value))}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField label="Max Audiences" value={maxAudiences} onChange={(e) => setMaxAudiences(e.target.value as string)} />
      <TextField label="Create Webinar Password" value={createWebinarPassword} onChange={(e) => setCreateWebinarPassword(e.target.value as string)} />
      <FormControlLabel
        control={(
          <Checkbox
            checked={recordingEnabled}
            onChange={(e) => setRecordingEnabled(e.target.checked as boolean)}
          />
        )}
        label="Enable recording"
      />
      <Button onClick={CreateWebinar} disabled={creatingWebinar}>
        {
          creatingWebinar ? 'Creating Webinar' : 'Create'
        }
      </Button>
      {webinar && (
        <>
          <div>
            {'Presenter URL: '}
            <a href={`/presenter/${webinar.eventDomain}/${webinar.id}?speaker_join_token=${webinar.speakerJoinToken}`} target="_blank" rel="noreferrer">
              {`https://${document.location.host}/presenter/${webinar.eventDomain}/${webinar.id}?speaker_join_token=${webinar.speakerJoinToken}`}
            </a>
          </div>
          <div>
            {'Attendee URL: '}
            <a href={`/audience/${webinar.eventDomain}/${webinar.id}?audience_join_token=${webinar.audienceJoinToken}`} target="_blank" rel="noreferrer">
              {`https://${document.location.host}/audience/${webinar.eventDomain}/${webinar.id}?audience_join_token=${webinar.audienceJoinToken}`}
            </a>
          </div>
        </>
      )}
      {error && (
        <ErrorMessage>
          {error}
        </ErrorMessage>
      )}
    </CreateFormContainer>
  );
};

export default CreateForm;
