import React, {
  FC,
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useObservable } from 'react-use';
import {
  defer,
  from,
  Observable,
  of,
} from 'rxjs';
import { finalize, mergeMap, catchError } from 'rxjs/operators';
import AgoraRTC, {
  IAgoraRTCClient,
  ILocalVideoTrack,
  ILocalAudioTrack,
  ILocalTrack,
} from 'agora-rtc-sdk-ng';
import WebinarSettings from './WebinarSettings';
import SpeakerNameModal from './SpeakerNameModal';
import NotSystemShareScreenPremissionModal from './NotSystemShareScreenPremissionModal';
import WebinarStatus from './WebinarStatus';
import WebinarInfo from './WebinarInfo';
import useQuery from '../../hooks/useQuery';
import startRecording from '../../network/startRecording';
import endRecording from '../../network/endRecording';
import fetchWebinar from '../../network/fetchWebinar';
import fetchSpeaker from '../../network/fetchSpeaker';
import fetchScreenShareToken from '../../network/fetchScreenShareToken';
import { webinar as WebinarType } from '../../model/webinar';
import { tokenInfo as tokenInfoType } from '../../model/tokenInfo';

const PresenterContainer = styled.div`
  width: 100%;
  max-width: 1350px;
  height: 100%;
  margin: auto;
`;

const WebinarVideoWrapper = styled.div`
  width: 100%;
  height: 600px;
  background-color: black;
  margin: 16px auto;
  position: relative;
  display: flex;
`;

const CameraVideoPlayer = styled.div<{ screenSharing: boolean; hasVideoTrack: boolean }>`
  width: ${({ screenSharing }) => (screenSharing ? '220px' : '100%')};
  height: ${({ screenSharing }) => (screenSharing ? '125px' : '100%')};
  padding: ${({ screenSharing }) => (screenSharing ? '16px' : 'initial')};
  background: black;
  display: ${({ hasVideoTrack, screenSharing }) => (screenSharing && !hasVideoTrack ? 'none' : 'block')};
  z-index: 1;
`;

const ScreenSharePlayer = styled.div<{ screenSharing: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ screenSharing }) => (screenSharing ? 'block' : 'none')}
`;

const WebinarStatusContainer = styled.div`
  padding: 8px;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
`;
enum connectionStateType {
  disconnected = -1,
  connecting = 0,
  connected = 1,
}

const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID || '';

const Presenter: FC = () => {
  const query = useQuery();
  const [connectionState, setConnectionState] = useState<number>(connectionStateType.disconnected);
  const [screenShareConnected, setScreenShareConnected] = useState(false);
  const [speakerName, setSpeakerName] = useState('');
  const [webinar, setWebinar] = useState<WebinarType>({} as WebinarType);
  const [isScreenShareEnabled, setIsScreenShareEnabled] = useState<boolean>(false);
  const [tokenInfo, setTokenInfo] = useState<tokenInfoType>({});
  const [screenShareTokenInfo, setScreenShareTokenInfo] = useState<tokenInfoType>({});
  const [isNotSystemShareScreenPremissionModalOpen, setIsNotSystemShareScreenPremissionModalOpen] = useState(false);
  const { eventDomain, webinarId } = useParams<{ eventDomain: string, webinarId: string }>();
  const speakerJoinToken = query.get('speaker_join_token') || '';

  const playerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const pipref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const client = useMemo(() => AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }), []) as IAgoraRTCClient;
  const shareScreenClient = useMemo(() => AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }), []) as IAgoraRTCClient;

  const init = useCallback(async () => {
    if (eventDomain && webinarId && speakerJoinToken && speakerName) {
      const webinarResult = await fetchWebinar(eventDomain, webinarId);
      if (webinarResult) {
        setWebinar(webinarResult);
        const speakerTokenInfo = await fetchSpeaker(eventDomain, webinarId, webinarResult, speakerJoinToken, speakerName);
        if (speakerTokenInfo) setTokenInfo(speakerTokenInfo);
        const screenShareToken = await fetchScreenShareToken(eventDomain, webinarId, webinarResult, speakerJoinToken, speakerName);
        if (screenShareToken) setScreenShareTokenInfo(screenShareToken);
      }
    }
  }, [eventDomain, webinarId, speakerJoinToken, speakerName]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (tokenInfo.agoraToken && tokenInfo.agoraUserId && webinar.agoraChannelId && connectionState < 0) {
      setConnectionState(connectionStateType.connecting);
      client.join(AGORA_APP_ID, webinar.agoraChannelId, tokenInfo.agoraToken, tokenInfo.agoraUserId)
        .then(() => setConnectionState(connectionStateType.connected));
    }
  }, [client, tokenInfo.agoraToken, tokenInfo.agoraUserId, webinar.agoraChannelId, connectionState]);

  useEffect(() => {
    if (screenShareTokenInfo.agoraToken && screenShareTokenInfo.agoraUserId && webinar.agoraChannelId && !screenShareConnected) {
      shareScreenClient.join(AGORA_APP_ID, webinar.agoraChannelId, screenShareTokenInfo.agoraToken, screenShareTokenInfo.agoraUserId)
        .then(() => setScreenShareConnected(true));
    }
  }, [shareScreenClient, screenShareTokenInfo.agoraToken, screenShareTokenInfo.agoraUserId, webinar.agoraChannelId, screenShareConnected]);

  const [isStreaming, setIsStreaming] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [selectedCameraId, setSelectedCameraId] = useState<string>('');

  const videoTrack$ = useMemo(() => (!isCameraOn || selectedCameraId == null ? of(null)
    : defer(() => from(AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId, encoderConfig: '1080p_2' }))
      .pipe(mergeMap((track) => of(track).pipe(
        mergeMap(() => new Observable<ILocalVideoTrack>((subscriber) => subscriber.next(track))),
        finalize(() => {
          track.close();
          track.stop();
        }),
      ))))), [isCameraOn, selectedCameraId]);
  const videoTrack = useObservable(videoTrack$);

  useEffect(() => {
    videoTrack?.play(playerRef.current, { fit: 'contain' });
    return () => videoTrack?.stop();
  }, [videoTrack]);

  const [selectedMicrophoneId, setSelectedMicrophoneId] = useState<string>('');
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(true);

  const audioTrack$ = useMemo(() => (!isMicrophoneOn || selectedMicrophoneId == null ? of(null)
    : defer(() => from(AgoraRTC.createMicrophoneAudioTrack({ microphoneId: selectedMicrophoneId }))
      .pipe(mergeMap((track) => of(track).pipe(
        mergeMap(() => new Observable<ILocalAudioTrack>((subscriber) => subscriber.next(track))),
        finalize(() => {
          track.close();
          track.stop();
        }),
      ))))), [isMicrophoneOn, selectedMicrophoneId]);
  const audioTrack = useObservable(audioTrack$);

  const screenShareTrack$ = useMemo(() => (!isScreenShareEnabled || isNotSystemShareScreenPremissionModalOpen ? of(null)
    : defer(() => from(AgoraRTC.createScreenVideoTrack({ encoderConfig: '1080p_2', screenSourceType: 'screen' }, 'auto'))
      .pipe(mergeMap((track) => of(track).pipe(
        mergeMap(() => new Observable<[ILocalVideoTrack, ILocalAudioTrack] | ILocalVideoTrack>((subscriber) => {
          if (Array.isArray(track)) {
            const [screenVideoTrack, screenAudioTrack] = track;
            screenVideoTrack.on('track-ended', () => {
              setIsScreenShareEnabled(false);
            });
            screenAudioTrack.on('track-ended', () => {
              setIsScreenShareEnabled(false);
            });
          } else {
            track.on('track-ended', () => {
              setIsScreenShareEnabled(false);
            });
          }
          subscriber.next(track);
        })),
        finalize(() => {
          if (Array.isArray(track)) {
            const [screenVideoTrack, screenAudioTrack] = track;
            screenVideoTrack.close();
            screenVideoTrack.stop();
            screenAudioTrack.close();
            screenAudioTrack.stop();
          } else {
            track.close();
            track.stop();
          }
        }),
      )),
      catchError((err: any, caught: Observable<ILocalVideoTrack | [ILocalVideoTrack, ILocalAudioTrack]>) => {
        const { code } = err;
        if (code === 'PERMISSION_DENIED') {
          setIsNotSystemShareScreenPremissionModalOpen(true);
        }
        setIsScreenShareEnabled(false);
        return err;
      })))), [isScreenShareEnabled, isNotSystemShareScreenPremissionModalOpen]);

  const screenShareTrack = useObservable(screenShareTrack$);

  useEffect(() => {
    if (shareScreenClient) {
      shareScreenClient.on('exception', ({ code }) => {
        if (code === 2003 || code === 1003) {
          setIsScreenShareEnabled(false);
        }
      });
    }
  }, [shareScreenClient, setIsScreenShareEnabled]);

  useEffect(() => {
    if (screenShareTrack) {
      if (Array.isArray(screenShareTrack)) {
        const [screenShareVideoTrack] = screenShareTrack as [ILocalVideoTrack, ILocalAudioTrack];
        screenShareVideoTrack?.play(pipref.current, { fit: 'contain' });
        return () => {
          const [dropScreenShareVideoTrack] = screenShareTrack as [ILocalVideoTrack, ILocalAudioTrack];
          dropScreenShareVideoTrack?.stop();
          setIsScreenShareEnabled(false);
        };
      }
      (screenShareTrack as ILocalVideoTrack)?.play(pipref.current, { fit: 'contain' });
      return () => {
        (screenShareTrack as ILocalTrack)?.stop();
        setIsScreenShareEnabled(false);
      };
    }
    return undefined;
  }, [screenShareTrack]);

  useEffect(() => {
    if (!isStreaming || videoTrack == null) {
      return undefined;
    }
    client.publish(videoTrack);
    return () => {
      client.unpublish(videoTrack);
    };
  }, [client, isStreaming, videoTrack]);

  useEffect(() => {
    if (!isStreaming || audioTrack == null) {
      return undefined;
    }
    client.publish(audioTrack);
    return () => {
      client.unpublish(audioTrack);
    };
  }, [client, isStreaming, audioTrack]);

  useEffect(() => {
    if (!isStreaming || !isScreenShareEnabled || screenShareTrack == null || shareScreenClient.connectionState !== 'CONNECTED') {
      return undefined;
    }
    shareScreenClient.publish(screenShareTrack as ILocalTrack | ILocalTrack[]);
    return () => {
      setIsScreenShareEnabled(false);
      shareScreenClient.unpublish(screenShareTrack as ILocalTrack | ILocalTrack[]);
    };
  }, [shareScreenClient, isScreenShareEnabled, isStreaming, screenShareTrack]);

  const handleRecordingStart = useCallback(() => {
    if (eventDomain && webinarId && webinar && webinar.recordingEnabled) {
      startRecording(eventDomain, webinarId, webinar);
    }
  }, [eventDomain, webinarId, webinar]);

  const handleRecordingEnd = useCallback(() => {
    if (eventDomain && webinarId && webinar && webinar.recordingEnabled) {
      endRecording(eventDomain, webinarId, webinar);
    }
  }, [eventDomain, webinarId, webinar]);

  return (
    <PresenterContainer>
      <NotSystemShareScreenPremissionModal
        isOpen={isNotSystemShareScreenPremissionModalOpen}
        setIsOpen={setIsNotSystemShareScreenPremissionModalOpen}
      />
      <SpeakerNameModal
        updateSpeakerName={setSpeakerName}
      />
      <WebinarInfo
        webinar={webinar}
      />
      <WebinarVideoWrapper>
        <WebinarStatusContainer>
          <WebinarStatus isStreaming={isStreaming} client={client} />
        </WebinarStatusContainer>
        <ScreenSharePlayer ref={pipref} screenSharing={isScreenShareEnabled && !!screenShareTrack} />
        <CameraVideoPlayer ref={playerRef} screenSharing={isScreenShareEnabled && !!screenShareTrack} hasVideoTrack={!!videoTrack} />
      </WebinarVideoWrapper>
      <WebinarSettings
        selectedCameraId={selectedCameraId}
        setSelectedCameraId={setSelectedCameraId}
        selectedMicrophoneId={selectedMicrophoneId}
        setSelectedMicrophoneId={setSelectedMicrophoneId}
        setIsCameraOn={setIsCameraOn}
        isCameraOn={isCameraOn}
        setIsMicrophoneOn={setIsMicrophoneOn}
        isMicrophoneOn={isMicrophoneOn}
        isStreaming={isStreaming}
        setIsStreaming={setIsStreaming}
        isScreenShareEnabled={isScreenShareEnabled}
        setIsScreenShareEnabled={setIsScreenShareEnabled}
        handleRecordingStart={handleRecordingStart}
        handleRecordingEnd={handleRecordingEnd}
        connectionState={connectionState}
      />
    </PresenterContainer>
  );
};

export default Presenter;
