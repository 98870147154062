import React, { FC } from 'react';
import styled from 'styled-components';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';

const StyledModal = styled(Modal)`
  display: flex,;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #efefef;
  display: flex;
  flex-direction: column;
`;

interface NotSystemShareScreenPremissionModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const NotSystemShareScreenPremissionModal: FC<NotSystemShareScreenPremissionModalProps> = ({ isOpen, setIsOpen }) => (
  <StyledModal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={isOpen}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={isOpen}>
      <ModalContent>
        <h2 id="transition-modal-title">Permission Denied</h2>
        <Typography>Please enable share screen permission for the browser</Typography>
        <Button onClick={() => setIsOpen(false)}>Close</Button>
      </ModalContent>
    </Fade>
  </StyledModal>
);

export default NotSystemShareScreenPremissionModal;
