import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useObservable } from 'react-use';
import { Observable } from 'rxjs';
import { IAgoraRTCClient, IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';

const WebinarStatusContainer = styled.div<{ isStreaming: boolean}>`
  background-color: ${({ isStreaming }) => (isStreaming ? 'red' : 'green')};
  color: white;
  border-radius: 4px;
  padding: 8px;
`;

interface WebinarStatusProps {
  isStreaming: boolean
  client: IAgoraRTCClient
}

const WebinarStatus: FC<WebinarStatusProps> = ({ isStreaming, client }) => {
  const liveStreamUsers$ = useMemo(() => new Observable<IAgoraRTCRemoteUser[]>((subscriber) => {
    const updateRemoteUsers = () => {
      subscriber.next(client.remoteUsers);
      subscriber.next(client.remoteUsers.filter((it: IAgoraRTCRemoteUser) => (it.uid as string).startsWith('audience')));
    };
    client.on('user-joined', () => updateRemoteUsers());
    client.on('user-left', () => updateRemoteUsers());
  }), [client]);

  const liveStreamUsersAmount = useObservable(liveStreamUsers$)?.length ?? 0;

  const renderStatusText = useMemo(() => {
    if (isStreaming) {
      return `Live: ${liveStreamUsersAmount}`;
    }
    return 'Preview';
  }, [isStreaming, liveStreamUsersAmount]);

  return (
    <WebinarStatusContainer isStreaming={isStreaming}>
      {renderStatusText}
    </WebinarStatusContainer>
  );
};

export default WebinarStatus;
