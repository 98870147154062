import axios from 'axios';

const API_URL = process.env.REACT_APP_VEXPO_API_URL;

const fetchSpeaker = async (
  eventDomain: string,
  webinarId: string,
  webinar: any,
  speakerJoinToken: string,
  speakerName: string,
) => (
  axios.post(`${API_URL}/webinarAgora/${eventDomain}/webinars/${webinarId}/screen-sharings`, {
    agoraWebinar: webinar,
    speakerName,
    speakerJoinToken,
  })
    .then(({ data }) => {
      if (data.success) {
        return data.tokenInfo;
      }
      return null;
    })
    .catch((error) => {
      console.log(error);
      return null;
    })
);

export default fetchSpeaker;
