import React, {
  FC,
  useMemo,
} from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { webinar as WebinarType } from '../../model/webinar';

const WebinarDetailsContainer = styled.div`
  padding: 16px;
`;

const WebinarTitleContainer = styled.div`
  font-weight: bold;
  padding: 4px
`;

const WebinarInfoContainer = styled.div`
  padding: 4px
`;

interface WebinarInfoProps {
  webinar: WebinarType
}

const WebinarInfo: FC<WebinarInfoProps> = ({ webinar }) => {
  const renderWebinarDate = useMemo(() => {
    if (webinar.timezone && webinar.startTime && webinar.endTime) {
      const startTimeObject = moment.utc(webinar.startTime).tz(webinar.timezone);
      const endTimeObject = moment.utc(webinar.endTime).tz(webinar.timezone);
      const gtm = `GTM ${moment().tz(webinar.timezone).format('ZZ')}`;
      if (moment.utc(webinar.startTime).tz(webinar.timezone).isSame(moment.utc(webinar.endTime).tz(webinar.timezone), 'days')) {
        const hoursRange = `${startTimeObject.format('HH:mm')} - ${endTimeObject.format('HH:mm')}`;
        const totalHours = `(${endTimeObject.diff(startTimeObject, 'minutes')} mins)`;
        const date = `${startTimeObject.format('Do MMMM YYYY')}`;
        return `${hoursRange} ${totalHours} ${gtm} ${date}`;
      }
      return `${startTimeObject.format('HH:mm Do MMMM YYYY')} - ${endTimeObject.format('HH:mm Do MMMM YYYY')} GTM ${moment().tz(webinar.timezone).format('ZZ')}`;
    }
    return null;
  }, [webinar]);

  return (
    <WebinarDetailsContainer>
      <WebinarTitleContainer>{webinar.name}</WebinarTitleContainer>
      <WebinarInfoContainer>{webinar.description}</WebinarInfoContainer>
      <WebinarInfoContainer>{renderWebinarDate}</WebinarInfoContainer>
    </WebinarDetailsContainer>
  );
};

export default WebinarInfo;
