import React, { FC } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const MainPlayer = styled.div<{ screenSharing: boolean; videoStreaming: boolean, isDesktop: boolean }>`
  width: ${({ screenSharing }) => (screenSharing ? '300px' : '100%')};
  height: ${({ screenSharing }) => (screenSharing ? '180px' : '100%')};
  padding: 16px;
  display:
  ${({
    videoStreaming,
    screenSharing,
    isDesktop,
  }) => (
    (screenSharing && (!videoStreaming || !isDesktop)) ? 'none' : 'block')};
  background: black;
  z-index: 1;
`;

const ShareScreenPlayer = styled.div<{ screenSharing: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ screenSharing }) => (screenSharing ? 'block' : 'none')}

`;

const ConnectStatusContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

interface LiveStreamPlayerProps {
  connected: boolean;
  audioTracks: any;
  videoStreaming: boolean;
  screenSharing: boolean;
  playerRef: any;
  shareScreenRef: any;
  trackingParam: any;
  eventDomain: string;
  webinarId: string;
}

const LiveStreamPlayer: FC<LiveStreamPlayerProps> = ({
  connected,
  audioTracks,
  videoStreaming,
  screenSharing,
  playerRef,
  shareScreenRef,
  trackingParam,
  eventDomain,
  webinarId,
}) => {
  const isDesktop = useMediaQuery('(min-width: 960px)');
  if (connected) {
    if (audioTracks.length > 0 || videoStreaming || screenSharing) {
      window.trackingItemToFirebase(eventDomain, trackingParam, 'Webinar', 'PlayVexpoLiveWebinar', 'isLive', 'StartPlaying', webinarId, trackingParam?.webinarName || '');
      return (
        <>
          <ShareScreenPlayer ref={shareScreenRef} screenSharing={screenSharing} />
          <MainPlayer ref={playerRef} screenSharing={screenSharing} videoStreaming={videoStreaming} isDesktop={isDesktop} />
        </>
      );
    }
    return (
      <ConnectStatusContainer>Waiting for the host to Start</ConnectStatusContainer>
    );
  }
  return (
    <ConnectStatusContainer>Connecting</ConnectStatusContainer>
  );
};

export default LiveStreamPlayer;
