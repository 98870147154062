import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const MetaData: FC = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>WLT</title>
    <meta name="description" content="EventX Webinar Live Stream Tool" />
  </Helmet>
);

export default MetaData;
