import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Audience from './components/audience';
import Presenter from './components/presenter';
import CreateForm from './components/CreateForm';
import MetaData from './components/MetaData';

const App = () => (
  <>
    <MetaData />
    <Router>
      <Switch>
        <Route path="/recording/:eventDomain/:webinarId">
          <Audience />
        </Route>
        <Route path="/audience/:eventDomain/:webinarId">
          <Audience />
        </Route>
        <Route path="/presenter/:eventDomain/:webinarId">
          <Presenter />
        </Route>
        <Route path="/create_form">
          <CreateForm />
        </Route>
      </Switch>
    </Router>
  </>
);

export default App;
